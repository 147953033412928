import React from 'react'
import Map from "../elements/GoogleMaps"



const MapInfo = () => {



    return (
        <>
        <div id="map">
            <Map />
        </div>
       
       <div>
            <div itemscope itemtype="http://schema.org/Organization">
            <h3  className="mt-4" itemProp="name">Barron Carpets &amp; Floors</h3>
            
            <address className="py-2 not-italic" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
      
            <span itemprop="streetAddress">24 North Leith Sands, </span>
                <span itemprop="postalCode">EH6 4ER, </span>
                <span itemprop="addressLocality">Edinburgh, Scotland</span>

            </address>
            </div>
        </div>
        </>
    )
}


export default MapInfo