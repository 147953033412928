import React from "react"
import Layout from "../components/Layout"
import Form from "../components/modules/ContactForm"
import Map from "../components/modules/MapInfo"
import SEO from "../components/SEO"


export default () => (
  <Layout>
    <SEO title="Contact Barron Carpets" description="Get in touch with the team at Barron carpets with any questions relating to your flooring needs." />

<section className="container section-spacer">
    <h1 className="text-white">Contact our team of fitters today</h1>



    <div className="md:flex">   
    
      <div className="w-full md:w-1/2 md:mr-4">
     
      <p className="dark">For any enquiries, please complete the form below. We will do our best to get back to you within one to two business days. <b>Note: all fields are required.</b></p> 
        <Form />
       
      </div>
      <div className="w-full md:w-1/2 md:ml-4 dark">
        <Map />
        <h3 className="mt-4">Opening Hours</h3>
        <p>
            To arrange an appointment please give us a call on <b>Telephone:</b> Tel: 07734 192839
        </p>
      </div>
    </div>
    </section>
  </Layout>
)

